import { useEffect } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import { useRouter } from 'next/router';
import { Text } from '@chakra-ui/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { APPLICATION_URL, PDP_CACHE_MAX_AGE, PDP_CACHE_STALE } from 'composable/components/general';
import { isPublicPage } from 'composable/helpers/utils/is-public-page';
import routes from 'helpers/constants/routes';
import { getServerShamrockRedirectUrl } from 'hooks/global/use_privateUserGlobal/utils';
import { createClient, ResponseError, LocaleStorage, useDarkMode, PageDataResponse } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import { Log } from '../helpers/errorLogger';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
  slug: string;
};

interface DataWithMessage {
  message?: string;
}

const SU_PREFIX = 'su';
const startTime = performance.now();

export default function Slug({ data, locale, slug }: SlugProps) {
  const { applyTheme } = useDarkMode();
  const router = useRouter();

  useEffect(() => {
    applyTheme(data?.pageFolder?.configuration?.theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pageFolder?.configuration]);

  useEffect(() => {
    if (
      router.asPath === '/' ||
      (router.asPath === '/[[...slug]]' && LOGROCKET_ID !== null && typeof window !== 'undefined' && window.LogRocket)
    ) {
      const loadTime = performance.now() - startTime;
      const payload = {
        totalTime: typeof loadTime === 'number' ? loadTime : 0,
      };
      window.LogRocket?.track('HomepageLoad', payload);
    }
  }, [router.asPath]);

  LocaleStorage.locale = locale;

  if (!data || typeof data === 'string') {
    return (
      <>
        <Text as="h1" mt={2} fontSize="4xl" fontWeight="extrabold" color="gray.900">
          Internal Error
        </Text>
        <Text mt={2} fontSize="lg">
          {data}
        </Text>
        <Text mt={2} fontSize="lg">
          Check the logs of your Frontastic CLI for more details.
        </Text>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <Text as="h1" mt={2} fontSize="4xl" fontWeight="extrabold" color="gray.900">
          Internal Error
        </Text>
        <Text mt={2} fontSize="lg">
          {data!.message}
        </Text>
        <Text mt={2} fontSize="lg">
          Check the logs of your Frontastic CLI for more details.
        </Text>
      </>
    );
  }

  return <FrontasticRenderer data={data} slug={slug} tastics={tastics} wrapperClassName={styles.gridWrapper} />;
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  LocaleStorage.locale = locale;
  const frontastic = createClient();

  if (query.errorAccessToken == 'true') {
    //let's redirect the customer to shamrock login page, so we get the correct accessToken
    const destination = getServerShamrockRedirectUrl(APPLICATION_URL, query.errorCode as string);
    return {
      redirect: {
        destination,
        statusCode: 301,
      } as Redirect,
    };
  }

  if ((query?.slug?.[0] as string) === SU_PREFIX && query?.slug?.length === 1) {
    return {
      redirect: {
        destination: routes.HOME,
        statusCode: 301,
      } as Redirect,
    };
  }

  const data = await frontastic.getRouteData(params, locale, query, req, res);

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      const slug = params?.slug;
      const destination = slug?.includes('public') ? routes.PUBLIC_NOT_FOUND : routes.NOT_FOUND;
      return {
        redirect: {
          destination,
          statusCode: 301,
        } as Redirect,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if ((data as DataWithMessage).message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  if (isPublicPage(data as PageDataResponse)) {
    res.setHeader('Cache-Control', `public, s-maxage=${PDP_CACHE_MAX_AGE}, stale-while-revalidate=${PDP_CACHE_STALE}`);
  }

  return {
    props: {
      data: data || null,
      locale: locale,
      slug: query.path,
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};
